import {
  defineStyleConfig,
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme
} from '@chakra-ui/react'
import { GlobalProps } from '@emotion/react'
import merge from 'lodash/merge'
import appConfig from '#/src/appConfig'
import { fonts } from './fonts'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const breakpoints = {
  xs: '30em',
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em'
}

const Checkbox = defineStyleConfig({
  defaultProps: {
    colorScheme: 'none'
  },
  baseStyle: {
    control: {
      _checked: {
        bg: 'primary'
      },
      _focus: {
        shadow: 'none'
      }
    }
  }
})

const Popover = defineStyleConfig({
  variants: {
    responsive: {
      popper: {
        maxWidth: {
          base: 'unset',
          md: '500px'
        }
      }
    }
  }
})

const Switch = defineStyleConfig({
  defaultProps: {
    colorScheme: 'gray'
  }
})

const Link = defineStyleConfig({
  baseStyle: {
    cursor: 'pointer'
  }
})

const Text = defineStyleConfig({
  baseStyle: {
    cursor: 'default'
  }
})

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 'sm',
    backgroundColor: 'primary',
    size: 'sm',
    color: 'text.light',
    fontWeight: 'medium',
    minW: 140,
    outlineOffset: 0,
    transition: 'all 0.35s cubic-bezier(.08,.52,.52,1)'
  }
})

const CustomFooter = defineStyleConfig({
  baseStyle: (props) => {
    const footerStyles = props.theme?.components?.CustomFooter || {}
    return {
      bg: 'background.surface',
      p: {
        base: 4,
        md: 10
      },
      ...footerStyles
    }
  }
})

const grayColorSchema = {
  950: '#0A2D31',
  900: '#323a3a',
  800: '#1F464C',
  700: '#3C6068',
  600: '#4B6D76',
  500: '#5A7A84',
  400: '#7B95A0',
  300: '#9DB1BC',
  200: '#AFC0C9',
  100: '#C2CED6',
  50: '#D4DDE3'
}

const primaryColorSchema = {
  900: '#17181D',
  800: '#1B1E23',
  700: '#002338',
  600: '#002D47',
  500: '#004269',
  400: '#005588',
  300: '#0069A7',
  200: '#4DB5D4',
  100: '#8DE8E8',
  50: '#C4F2F2'
}

const defaultTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'primary'
  }),
  {
    components: {
      Checkbox,
      Popover,
      Switch,
      Link,
      Text,
      Button,
      CustomFooter
    },
    config,
    breakpoints,
    styles: {
      global: (_props: GlobalProps) => ({
        html: {},
        body: {},
        '#__next': {},
        ':root': {
          // Add root styles or variables here. i.e.
          // '--chakra-colors-name-of-the-color': mode(
          //     '#FDFDFF',
          //     brand[1000]
          // )( props ),
        }
      })
    },
    colors: {
      primary: primaryColorSchema,
      // primary: '#315d91', //default accent
      secondary: '#315d91', //focus accent,
      // Value should be a CSS hex color, e.g. "#FF9900". RegExp: ^#[A-Fa-f0-9]{6}$
      klarna: '#315d91',
      background: {
        default: '#FFF',
        surface: '#f4f4f4',
        mildDark: 'rgb(1,50,75)',
        dark: 'rgb(0,25,38)',
        darkest: 'rgb(0,19,28)',
        translucent: 'rgba(244,244,244, 0.90)',
        overlayHalf: 'rgba(0,0,0, 0.25)',
        overlay: 'rgba(0,0,0, 0.5)',
        primary: 'rgba(146,176,156, .1)', //transparentized primary
        secondary: 'rgba(205,15,50, .1)' //transparentized secondary
      },
      text: {
        // default: primaryColorSchema[500],
        disabled: primaryColorSchema[300],
        mild: grayColorSchema[500],
        light: 'white',
        darkGrey: grayColorSchema[950],
        colored: '#315d91'
      },
      status: {
        success: 'rgba(29,195,149, 1)',
        warning: '#eed892',
        warning2: '#f5e6d6',
        error: '#f35f5f'
      },
      brand: {
        ...grayColorSchema,
        darkExtraLight: 'rgba(0,25,38,0.20)',
        darkGrey: '#222222'
      },
      landing: {
        bg: {
          primary: primaryColorSchema
        }
      }
    },
    semanticTokens: {
      colors: {
        'chakra-body-text': { _light: primaryColorSchema[600], _dark: 'whiteAlpha.900' },
        primary: {
          default: 'primary.400'
        },
        text: {
          default: 'primary.600'
        }
      }
    },
    textStyles: {
      slogan: {
        fontFamily: 'var(--chakra-fonts-slogan)'
      },
      landingBody: {
        fontFamily: 'var(--chakra-fonts-landingBody)'
      },
      landingHeading: {
        fontFamily: 'var(--chakra-fonts-landingHeading)'
      },
      landingSlogan: {
        fontFamily: 'var(--chakra-fonts-landingSlogan)'
      }
    },
    textDecoration: {
      underline: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationColor: 'color.highlight'
      }
    },
    shadows: {
      //the 'outline' shadow preset is replaced with the default accent color: secondary
      outline: '0 0 0 2px #cd0f32',
      //outline style shadows for each preset,
      primary: '0 0 0 2px #92b09c',
      secondary: '0 0 0 2px #cd0f32',
      //blur style shadows for each preset,
      primaryBlur: '0 0 4px 2px #92b09c',
      secondaryBlur: '0 0 4px 2px #cd0f32'
    },
    fonts
  }
)

export const customTheme = merge(defaultTheme, appConfig.theme)
